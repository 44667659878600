import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { H2, Card } from '@blueprintjs/core'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../style/portfolio.scss'

export const query = graphql`
    query PortfolioItemsQuery {
        allContentfulPortfolioItem {
            edges {
                node {
                    id
                    title
                    description
                    link
                    heroImage {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`

const PortfolioPhoto = ({ src, caption, link, sensitive }: any) => {
    const handleClick = () => {
        if (link) window.location = link
    }
    return (
        <Card
            className={`PortfolioPhoto ${link ? 'link' : ''}`}
            elevation={link ? 3 : 1}
            onClick={handleClick}
        >
            <div
                style={{
                    position: 'relative',
                }}
            >
                <GatsbyImage
                    className={`portfolio-image ${
                        sensitive ? 'sensitive' : ''
                    }`}
                    image={src}
                    alt={caption}
                />
                {sensitive && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            placeItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <h2 style={{ color: 'red' }}>Sensitive</h2>
                    </div>
                )}
            </div>
            <span>{caption}</span>
        </Card>
    )
}

const Portfolio = ({ data }: any) => {
    const portfolioItems = data.allContentfulPortfolioItem.edges
        .map(({ node }: any) => node)
        .sort((a: any, b: any) => (a.title > b.title ? 1 : -1))
    return (
        <Layout page='/portfolio'>
            <SEO title='Portfolio' />
            <div className='Portfolio'>
                <H2 className='header'>Portfolio</H2>
                <div className='wrapper'>
                    {portfolioItems.map((i: any) => (
                        <PortfolioPhoto
                            key={i.id}
                            src={getImage(i.heroImage)}
                            sensitive={i.sensitive}
                            link={i.link}
                            caption={i.description}
                        />
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default Portfolio
